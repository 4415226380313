/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { getCustomerData } from '../data/customer';
import { GTM_EVENT_KEY_NOT_FOUND } from '../util/events';
import { pushToDataLayer } from '../util/push';
import { debounceCallback } from '../util/wait';

/** @namespace Topsport/Gtm/Event/General/fireNotFoundEvent */
export const fireNotFoundEvent = debounceCallback(async () => {
    const { customerId } = await getCustomerData();

    pushToDataLayer({
        event: GTM_EVENT_KEY_NOT_FOUND,
        customerId,
        pageURL: window.location.href,
        eventCategory: '404 pages',
        eventLabel: ''
        // TODO: understand if label and category where required?
    }); // TODO: The name spaces are not provided for some const and functions
});
